<template>
  <h3 :id="customId" class="ContentProseH3" >
    <slot/>
  </h3>
</template>

<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    default: '',
  }
})

const customId = computed<string>(() => {
  return `h3-${props.id}`
})
</script>

<style lang="scss">
.ContentProseH3 {
  margin-bottom: var(--spacing-2x);
  margin-top: var(--spacing-4x);
}
</style>
